import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Field } from 'formik';
import { semibold } from '@toolshed/styleguide/styles/typography';
import AutoSuggest from '@toolshed/styleguide/components/AutoSuggest/AutoSuggest';
import { Option } from '@toolshed/styleguide/components/AutoSuggest/styles';

export const NoResults = styled(Option)`
  ${semibold()};
  text-align: left;
`;

const valueKey = 'value';

const AutoSuggestTraderField = ({
  suggestions,
  children,
  handleInputChange,
}) => (
  <Field name="traderLabel">
    {({ field, form }) => {
      const inputChange = value => {
        form.setFieldValue('uniqueName', '', false);
        form.setFieldValue('traderLabel', value, false);
        handleInputChange(value);
      };

      const inputCommit = ({ [valueKey]: value, label, ttrId, traderId }) => {
        form.setFieldValue('uniqueName', value, false);
        form.setFieldValue('traderLabel', label, false);
        form.setFieldValue('ttrId', ttrId, false);
        form.setFieldValue('traderId', traderId, false);
        form.submitForm();
      };

      const formSubmit = () => form.submitForm();

      return (
        <AutoSuggest
          defaultIndex={0}
          fieldValue={field.value}
          suggestions={suggestions}
          inputChange={inputChange}
          inputCommit={inputCommit}
          formSubmit={formSubmit}
          extraField="location"
          valueKey={valueKey}
          hasSelectedBorder={false}
          ExtraOptions={() => {
            if (!suggestions || !field.value) {
              return null;
            }

            return (
              <>
                {!suggestions.length && (
                  <NoResults>Sorry, no results found</NoResults>
                )}
              </>
            );
          }}
        >
          {({ suggestProps }) => {
            return children({ ...field, ...suggestProps });
          }}
        </AutoSuggest>
      );
    }}
  </Field>
);

AutoSuggestTraderField.propTypes = {
  children: PropTypes.func,
  suggestions: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      location: PropTypes.string,
      traderId: PropTypes.number,
      ttrId: PropTypes.string,
      value: PropTypes.string,
    }),
  ),
  handleInputChange: PropTypes.func,
};

export default AutoSuggestTraderField;
