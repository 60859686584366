import styled from 'styled-components';

const ScreenReaderOnly = styled.div`
  height: 1px;
  overflow: hidden;
  position: absolute;
  width: 1px;
`;

export default ScreenReaderOnly;
