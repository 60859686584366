import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Suggestions from './Suggestions';
import { SuggestWrapper, ErrorWrapper, ErrorMessage } from './styles';

export const SuggestionShape = PropTypes.shape({
  label: PropTypes.string,
  value: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
});

const AutoSuggest = ({
  defaultIndex,
  fieldValue,
  children,
  suggestions,
  inputChange,
  inputCommit,
  formSubmit,
  extraField,
  ExtraOptions,
  valueKey,
  customWidth,
  errorMessage,
  handleFocus,
  hasSelectedBorder,
}) => {
  const [active, setActive] = useState(false);
  const [index, setIndex] = useState(defaultIndex);

  useEffect(() => {
    if (index > suggestions.length) {
      setIndex(defaultIndex);
    }
  }, [suggestions, index, defaultIndex]);

  useEffect(() => {
    /* istanbul ignore next */
    const handler = () => {
      setActive(false);
    };

    /* istanbul ignore next */
    const tabHandler = e => {
      if (e.key === 'Tab') {
        setActive(false);
      }
    };

    document.addEventListener('click', handler);
    document.addEventListener('keyup', tabHandler);

    return () => {
      document.removeEventListener('click', handler);
      document.removeEventListener('keyup', tabHandler);
    };
  }, []);

  // Spec covered but istanbul does not pick it up form. methods
  // for some reason
  /* istanbul ignore next */
  /* eslint-disable consistent-return */
  const handleActiveMenu = key => {
    if (key === 'Escape') {
      setActive(false);
      return;
    }

    if (key === 'ArrowDown') {
      setIndex(Math.min(index + 1, suggestions.length - 1));
      return;
    }

    if (key === 'ArrowUp' && index === 0) {
      setIndex(defaultIndex);

      if (defaultIndex === -1) {
        setActive(false);
      }

      return;
    }

    if (key === 'Tab' && suggestions[index]) {
      inputCommit(suggestions[index]);
      setActive(false);
    }

    if (key === 'ArrowUp') {
      setIndex(Math.max(-1, index - 1));
      return;
    }

    if (
      key === 'Enter' &&
      active &&
      suggestions.length === 1 &&
      (fieldValue || '').trim() === suggestions[0].label
    ) {
      inputCommit(suggestions[0]);
      setActive(false);
      return;
    }

    if (key === 'Enter' && active && suggestions[index]) {
      inputCommit(suggestions[index]);
      setActive(false);
    }
  };

  /* istanbul ignore next */
  const handleControlKeys = key => {
    if (active) {
      return handleActiveMenu(key);
    }

    if (key === 'ArrowDown') {
      setActive(true);

      setIndex(Math.min(index + 1, suggestions.length - 1));
      return;
    }

    if (key === 'Enter') {
      setActive(false);
      return formSubmit();
    }
  };

  const handleKeyDown = e => {
    const { key } = e;

    const handledKeys = ['Enter', 'ArrowDown', 'ArrowUp', 'Escape', 'Tab'];

    if (handledKeys.includes(key)) {
      if (key !== 'Tab') {
        e.preventDefault();
      }

      handleControlKeys(key);
      return;
    }

    setActive(true);
  };

  const handleInputChange = e => {
    const { value } = e.target;

    inputChange(value);
  };

  const handleSuggestionClick = suggestion => {
    setIndex(suggestion.index);
    inputCommit(suggestion);
    setActive(false);
  };

  const handleBlur = () => {
    const sanitise = val => val.toLowerCase().replace(/ /g, '');

    if (
      suggestions.length &&
      fieldValue &&
      sanitise(fieldValue) === sanitise(suggestions[0].label)
    ) {
      inputCommit(suggestions[0]);
    }
  };

  const handleSuggestionMouseOver = suggestion => {
    setIndex(suggestion.index);
  };

  const suggestProps = {
    autoComplete: 'off',
    onKeyDown: handleKeyDown,
    onChange: handleInputChange,
    onBlur: handleBlur,
    onFocus: handleFocus,
  };

  const show = active && suggestions.length > 0;

  const width = customWidth ? `${customWidth}px` : `${100}%`;

  return (
    <SuggestWrapper style={{ width }}>
      {children({
        suggestProps,
        autoSuggestInactive: !active,
      })}

      {show && (
        <Suggestions
          id="search-page-suggestions"
          valueKey={valueKey}
          data={suggestions}
          onChange={handleSuggestionClick}
          onOptionMouseOver={handleSuggestionMouseOver}
          selectedIndex={index}
          extraField={extraField}
          ExtraOptions={ExtraOptions}
          hasSelectedBorder={hasSelectedBorder}
        />
      )}

      {errorMessage && !show && (
        <ErrorWrapper>
          <ErrorMessage>{errorMessage}</ErrorMessage>
        </ErrorWrapper>
      )}
    </SuggestWrapper>
  );
};

AutoSuggest.defaultProps = {
  defaultIndex: -1,
  valueKey: 'value',
  hasSelectedBorder: true,
  formSubmit: () => {},
  suggestions: [],
};

AutoSuggest.propTypes = {
  valueKey: PropTypes.string,
  defaultIndex: PropTypes.number,
  suggestions: PropTypes.arrayOf(SuggestionShape),
  children: PropTypes.func,
  fieldValue: PropTypes.string,
  inputChange: PropTypes.func,
  inputCommit: PropTypes.func,
  formSubmit: PropTypes.func,
  extraField: PropTypes.string,
  ExtraOptions: PropTypes.any,
  errorMessage: PropTypes.string,
  customWidth: PropTypes.number,
  handleFocus: PropTypes.func,
  hasSelectedBorder: PropTypes.bool,
};

export default AutoSuggest;
