import styled from 'styled-components';
import {
  BORDER_RADIUS,
  BOX_SHADOW,
  INPUT_BORDER_LEFT,
} from '@toolshed/styleguide/styles/constants';
import colors, { colorFaffBlue } from '@toolshed/styleguide/styles/colors';
import spacing from '@toolshed/styleguide/styles/spacing';
import { regular, semibold } from '@toolshed/styleguide/styles/typography';
import { spacing1, spacing2 } from 'lib/mortar';

export const OPTION_HEIGHT = 50;

export const SuggestWrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
`;

export const Wrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: auto;
  transform: translateY(-4px);
  overflow-x: hidden;
  overflow-y: visible;
  z-index: 15;
  background-color: ${colors.white};
  border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
  border: 1px solid ${colors.light};
  border-top: none;
  border-bottom-left-radius: ${BORDER_RADIUS};
  border-bottom-right-radius: ${BORDER_RADIUS};

  ${({ hasSelectedBorder }) =>
    hasSelectedBorder
      ? `
    border-left: ${INPUT_BORDER_LEFT}px solid ${colors.blue};
  `
      : null}
`;

export const ListWrapper = styled.ul`
  padding: 0;
  position: relative;
  background-color: ${colors.white};
  overflow: hidden;
`;

export const Option = styled.li`
  height: ${OPTION_HEIGHT}px;
  ${regular('smedium')};
  color: ${colors.regular};
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-sizing: border-box;
  padding: ${spacing1} ${spacing.medium - (INPUT_BORDER_LEFT - 1)}px;
  max-width: 100%;
  text-align: left;
  cursor: pointer;

  ${({ highlighted }) =>
    highlighted &&
    `
      background-color: ${colorFaffBlue};
      font-weight: bold;
    `}

  ${({ selected }) =>
    !selected
      ? undefined
      : `
        background-color: ${colors.dropdownBackground};
        color: ${colors.blue};
        border-top: 1px solid ${colors.blue};
        border-bottom: 1px solid ${colors.blue};
      `};
`;

export const ExtraField = styled.span`
  text-align: right;
`;

export const ErrorWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: auto;
  transform: translateY(-${spacing2});
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: visible;
  z-index: 1;
  background-color: ${colors.white};
  border-radius: 0 0 ${BORDER_RADIUS} ${BORDER_RADIUS};
  box-shadow: ${BOX_SHADOW};
`;

export const ErrorMessage = styled.div`
  ${semibold('base')};
  color: ${colors.red};
  box-sizing: border-box;
  padding: ${spacing1} ${spacing2};
  padding-top: ${spacing.medium}px;
  min-height: 47px;
  align-items: flex-end;
  text-align: left;
`;
