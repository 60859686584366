import type { MouseEventHandler } from 'react';
import React from 'react';
import styled from 'styled-components';

const Wrapper = styled.button`
  background: none;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }
`;

const AppIconButton = ({
  className,
  onClick,
  tabIndex,
  children,
  ...props
}: any) => (
  <Wrapper
    className={className}
    tabIndex={tabIndex || 0}
    type="button"
    onClick={onClick as MouseEventHandler}
    {...props}
  >
    {children}
  </Wrapper>
);

export default AppIconButton;
