import styled from 'styled-components';
import { BOX_SHADOW } from '@styles/global-style';
import spacing from '@styles/spacing';
import colors, {
  colorBackgroundDefault,
} from '@toolshed/styleguide/styles/colors';
import { semibold } from '@toolshed/styleguide/styles/typography';
import tablet from '@styles/breakpoint/tablet';
import { Label, Field } from '@toolshed/styleguide/components/Input/Input';
import { spacing1, spacing2 } from 'lib/mortar';

export const ErrorWrapper = styled.div`
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  bottom: auto;
  transform: translateY(-10px);
  max-height: 200px;
  overflow-x: hidden;
  overflow-y: visible;
  z-index: 11;
  background-color: ${colorBackgroundDefault};
  border-radius: 0 0 ${spacing.navBorder}px ${spacing.navBorder}px;
  box-shadow: ${BOX_SHADOW};
`;

export const ErrorMessage = styled.div`
  ${semibold('base')};
  color: ${colors.red};
  box-sizing: border-box;
  padding: ${spacing1} ${spacing2};
  padding-top: ${spacing2};
  min-height: 40px;
  align-items: flex-end;
  text-align: left;
`;

const formInputSpacing = spacing2;

export const Form = styled.form`
  margin: 0 -${formInputSpacing} 0 0;
  display: flex;
  flex-wrap: wrap;
`;

// On tablet, attempt to make the search wrapper take up 3x the space of the postcode and search button. Default to 230px
export const SearchInputWrapper = styled.div`
  margin: 0 ${formInputSpacing} ${spacing2} 0;
  flex-grow: 2;
  min-width: 240px;
  ${tablet(`
    flex: 3 0 230px;
  `)}
`;

export const SecondLevelWrapper = styled.div<{
  fullWidthPostcodeOnMobile?: boolean;
}>`
  display: flex;
  flex-grow: 1;

  ${({ fullWidthPostcodeOnMobile }) =>
    fullWidthPostcodeOnMobile &&
    `
      display: block;
      margin-right: ${spacing2};

      ${tablet(`
        display: flex;
        margin-right: 0;
      `)}

      > button {
        width: 100%;
      }
  `}
`;

export const LocationWrapper = styled.div<{
  fullWidthPostcodeOnMobile?: boolean;
}>`
  position: relative;
  margin-right: ${formInputSpacing};
  flex: 1;
  z-index: 1;
  // 2-line placeholder text for search location input field
  ${Field} {
    & + ${Label} {
      display: flex;
      flex-direction: column;
      top: calc(50% - 18px);
      line-height: 18px;
      &.filled {
        flex-direction: row;
        gap: 4px;
        top: 6px;
      }
    }
    &.input-focus,
    &:focus {
      & + ${Label} {
        flex-direction: row;
        gap: 4px;
        top: 6px;
      }
    }
  }

  ${({ fullWidthPostcodeOnMobile }) =>
    fullWidthPostcodeOnMobile &&
    `
      position: relative;
      margin: 0 ${spacing.medium}px ${spacing2} 0;
      width: 100%;
      ${tablet(`
        margin: 0 ${spacing.medium}px 0 0;
        flex: 1 0 40px;
      `)}
  `}
`;
