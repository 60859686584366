import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import getInputStateClassNames from '@utils/get-input-state-classnames';
import colors, {
  colorTextDisabled,
  colorBackgroundDisabled,
  colorBorderDisabled,
} from '@toolshed/styleguide/styles/colors';
import { sizes, regular } from '@toolshed/styleguide/styles/typography';
import spacing from '@toolshed/styleguide/styles/spacing';
import {
  INPUT_RADIUS,
  INPUT_BORDER_LEFT,
} from '@toolshed/styleguide/styles/constants';
import { spacing2 } from 'lib/mortar';

const timing = '.2s';
const iconTextIndent = 40;

export const Label = styled.label.attrs(({ filled }) => ({
  className: `
  ${filled ? 'filled' : ''}
  `,
}))`
  ${regular('smedium')};
  color: ${colors.medium};
  display: block;
  line-height: ${sizes.large}px;
  position: absolute;
  top: calc(50% - ${sizes.small}px);
  left: ${spacing.medium}px;
  margin: 0;
  ${({ hasIcon }) =>
    hasIcon ? `padding-left: ${iconTextIndent - 15}px;` : ``};
  pointer-events: none;
  user-select: none;
  transition:
    font-size ${timing} ease,
    top ${timing} ease,
    color ${timing} ease;
  width: calc(100% - ${sizes.smedium * 2}px);
  text-align: left;

  &.filled {
    ${regular('small')}
    line-height: ${sizes.lbase}px;
    top: 6px;
  }
`;

export const Field = styled.input.attrs(
  ({ showError, showFocus, showValid, disabled }) => ({
    className: getInputStateClassNames({ showFocus, showError, disabled }),
    styles: {
      paddingRight: showValid ? spacing2 : null,
    },
  }),
)`
  ${regular('smedium')};
  border: 1px solid ${colors.regular};
  border-radius: ${({ showError }) =>
    showError ? `${INPUT_RADIUS} ${INPUT_RADIUS} 0 0` : `${INPUT_RADIUS}`};
  box-sizing: border-box;
  color: ${colors.dark};
  outline: none;
  padding: ${sizes.smedium}px;
  height: 50px;
  width: 100%;
  ${({ hasLabel }) => (hasLabel ? `padding-top: ${spacing.xxlarge}px` : ``)};
  transition: border-color ${timing} ease;
  ${({ hasIcon }) => (hasIcon ? `padding-left: ${iconTextIndent}px;` : ``)};

  &[disabled],
  &.disabled {
    color: ${colorTextDisabled};
    cursor: not-allowed;
    background-color: ${colorBackgroundDisabled};
    border-color: ${colorBorderDisabled};

    & + ${Label} {
      color: ${colorTextDisabled};
    }
  }

  &[required] {
    & + ${Label}::after {
      content: '*';
    }
  }

  &.input-error,
  &.input-error.input-focus,
  &.input-error:focus {
    border-color: ${colors.red};
    color: ${colors.red};

    ${({ hasSelectedBorder }) =>
      hasSelectedBorder
        ? `
        border-left-width: ${INPUT_BORDER_LEFT}px;
        padding-left: ${sizes.smedium - (INPUT_BORDER_LEFT - 1)}px;
      `
        : null}

    & + ${Label} {
      color: ${colors.red};
    }
  }
  &.input-focus,
  &:focus {
    ${({ hasSelectedBorder }) =>
      hasSelectedBorder
        ? `
        border-color: ${colors.blue};
        border-left-width: ${INPUT_BORDER_LEFT}px;
        padding-left: ${sizes.smedium - (INPUT_BORDER_LEFT - 1)}px;
      `
        : null}

    & + ${Label} {
      ${regular('small')}
      line-height: ${sizes.lbase}px;
      top: 6px;
    }
  }
`;

const InputGroup = styled.div`
  position: relative;
  display: inline-block;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const InputIconWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: flex-end;
  justify-self: flex-end;
  position: absolute;
  top: 0;
  bottom: 0;
  cursor: pointer;
  padding-left: 5px;
`;

const Input = ({
  label,
  required,
  name,
  value,
  // eslint-disable-next-line
  placeholder, // Removed so placeholder isn't passed in with the rest of the `props` to the input field
  hasSelectedBorder,
  disableKeyboard = false,
  onChange,
  showError,
  icon = undefined,
  ...props
}) => {
  const [filled, setFilled] = useState(!!value);

  useEffect(() => setFilled(!!value), [value]);

  const handleChange = useMemo(
    () => (disableKeyboard ? null : onChange),
    [disableKeyboard, onChange],
  );

  return (
    <InputGroup>
      <Field
        hasLabel={label}
        aria-label={label}
        value={value}
        onKeyUp={e => setFilled(!!e.target.value)}
        onChange={handleChange}
        name={name}
        required={required}
        hasSelectedBorder={hasSelectedBorder}
        showError={showError}
        hasIcon={icon ? true : false}
        {...props}
      />
      {icon && <InputIconWrapper>{icon}</InputIconWrapper>}
      {label && (
        <Label filled={filled} htmlFor={name} hasIcon={icon ? true : false}>
          {label}
        </Label>
      )}
    </InputGroup>
  );
};

Input.defaultProps = {
  hasSelectedBorder: true,
  type: 'text',
  disableKeyboard: false,
};

Input.propTypes = {
  showError: PropTypes.bool,
  showFocus: PropTypes.bool,
  showValid: PropTypes.bool,
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  name: PropTypes.string,
  value: PropTypes.string,
  placeholder: PropTypes.string,
  hasSelectedBorder: PropTypes.bool,
  disableKeyboard: PropTypes.bool,
  onChange: PropTypes.func,
};

export default Input;
