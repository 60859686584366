import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import SuggestionsList from './SuggestionsList';
import { Wrapper } from './styles';

export const PADDING_TOP = 10;
export const MAX_HEIGHT = 200;

export const scrollToSelectedOption = (wrapperNode, id, selectedIndex) => {
  const { scrollTop, clientHeight: wrapperInnerHeight } = wrapperNode;
  const { offsetTop: itemOffsetTop, offsetHeight: itemHeight } =
    document.getElementById(`${id}-select-value--${selectedIndex}`) || {};
  const visible =
    itemOffsetTop >= scrollTop &&
    itemOffsetTop + itemHeight <= scrollTop + wrapperInnerHeight;

  if (visible) {
    return;
  }
  wrapperNode.scrollTop = // eslint-disable-line
    itemOffsetTop < scrollTop
      ? itemOffsetTop - PADDING_TOP
      : itemOffsetTop - (wrapperInnerHeight - itemHeight);
};

export const Suggestions = ({
  id,
  onChange,
  onOptionMouseOver,
  selectedIndex,
  data,
  extraField,
  valueKey,
  ExtraOptions,
  hasSelectedBorder,
}) => {
  const wrapperRef = useRef(null);
  // make sure selected option is visible
  useEffect(() => {
    scrollToSelectedOption(wrapperRef.current, id, selectedIndex);
  }, [wrapperRef, selectedIndex]);

  return (
    <Wrapper
      ref={wrapperRef}
      id={id}
      style={{ maxHeight: MAX_HEIGHT }}
      hasSelectedBorder={hasSelectedBorder}
    >
      <SuggestionsList
        data={data}
        wrapperId={id}
        selectedIndex={selectedIndex}
        onChange={onChange}
        onOptionMouseOver={onOptionMouseOver}
        extraField={extraField}
        ExtraOptions={ExtraOptions}
        valueKey={valueKey}
      />
    </Wrapper>
  );
};

Suggestions.defaultProps = {
  hasSelectedBorder: true,
  data: [],
};

Suggestions.propTypes = {
  id: PropTypes.string,
  onChange: PropTypes.func,
  onOptionMouseOver: PropTypes.func,
  selectedIndex: PropTypes.number,
  data: PropTypes.array,
  extraField: PropTypes.string,
  ExtraOptions: PropTypes.any,
  valueKey: PropTypes.string,
  hasSelectedBorder: PropTypes.bool,
};

export default Suggestions;
