import React from 'react';
import PropTypes from 'prop-types';
import { ListWrapper, Option, ExtraField } from './styles';

export const SuggestionsList = ({
  data,
  onChange,
  onOptionMouseOver,
  selectedIndex,
  wrapperId,
  extraField,
  ExtraOptions,
  valueKey,
}) => {
  if (!data.length) {
    return null;
  }

  return (
    <ListWrapper>
      {data.map(
        (
          {
            [valueKey]: value,
            label,
            // eslint-disable-next-line
            score,
            highlighted,
            ...other
          },
          index,
        ) => {
          const suggestion = {
            [valueKey]: value,
            label,
            index,
            ...other,
          };

          return (
            <Option
              onClick={() => onChange(suggestion)}
              key={`select-value--${value}--${label}`}
              id={`${wrapperId}-select-value--${index}`}
              onMouseOver={() => onOptionMouseOver(suggestion)}
              onFocus={() => onOptionMouseOver(suggestion)}
              selected={index === selectedIndex}
              highlighted={highlighted}
              aria-label={label}
            >
              <span>{label}</span>
              {extraField && other[extraField] && (
                <ExtraField>{other[extraField]}</ExtraField>
              )}
            </Option>
          );
        },
      )}

      {ExtraOptions && <ExtraOptions />}
    </ListWrapper>
  );
};

SuggestionsList.defaultProps = {
  data: [],
  valueKey: 'value',
  onOptionMouseOver: () => {},
};

SuggestionsList.propTypes = {
  valueKey: PropTypes.string,
  data: PropTypes.array,
  selectedIndex: PropTypes.number,
  onOptionMouseOver: PropTypes.func,
  onChange: PropTypes.func,
  wrapperId: PropTypes.string,
  extraField: PropTypes.string,
  ExtraOptions: PropTypes.any,
};

export default SuggestionsList;
