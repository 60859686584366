interface getInputStateClassNamesProps {
  showError?: boolean;
  showFocus?: boolean;
  disabled?: boolean;
}

const getInputStateClassnames = ({
  showError,
  showFocus,
  disabled,
}: getInputStateClassNamesProps) => {
  const inputError = showError && 'input-error';
  const focusError = showFocus && 'input-focus';
  const isDisabled = disabled && 'disabled';
  return [inputError, focusError, isDisabled].filter(Boolean).join(' ');
};

export default getInputStateClassnames;
