import React from 'react';
import styled from 'styled-components';
import Input from '@toolshed/styleguide/components/Input/Input';
import { Formik } from 'formik';
import AutoSuggestTraderField from 'components/AutoSuggest/AutoSuggestTraderField';
import { Form } from './styles';
import type { TradeSearchListItem, TradeSearchOnSubmit } from './types';

const TraderSearchForm = styled(Form)`
  margin: 0;
`;

const SearchInputWrapper = styled.div`
  width: 100%;
  margin: 0;
  z-index: 100;
`;

const TraderSearch = ({
  tradesByName,
  onTradeSearch,
  onSubmit,
}: TraderSearchProps) => (
  <Formik
    initialValues={{
      uniqueName: '',
      traderLabel: '',
      traderId: '',
      ttrId: '',
    }}
    enableReinitialize
    onSubmit={values => onSubmit(values)}
  >
    {({ handleSubmit }) => (
      <TraderSearchForm
        aria-label="Find trade by company name"
        onSubmit={handleSubmit}
        role="search"
      >
        <SearchInputWrapper>
          <AutoSuggestTraderField
            suggestions={tradesByName}
            handleInputChange={onTradeSearch}
          >
            {props => (
              <Input
                type="text"
                placeholder="Company name"
                aria-label="Company name"
                label="Company name"
                {...props}
              />
            )}
          </AutoSuggestTraderField>
        </SearchInputWrapper>
      </TraderSearchForm>
    )}
  </Formik>
);

export interface TraderSearchProps {
  tradesByName?: TradeSearchListItem[];
  onTradeSearch: (value: string) => void;
  onSubmit: TradeSearchOnSubmit;
}

export default TraderSearch;
